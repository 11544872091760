
import { computed, defineComponent, ref, getCurrentInstance } from "vue"
import { CollectionResourceDoc } from '@/models/jsonapi'
import { Household } from "@/models/households"
import { BvTableCtxObject } from "bootstrap-vue"
import { apiDataMorpher, extractRelationshipIds } from "@/utils/apiDataHelper"
import { fullName } from '@/utils/dataExtractors'

export default defineComponent({
  setup(_) {
    const root = getCurrentInstance().proxy
    const totalItems = computed(() => root.$store.state.households.pagination.totalItems)

    const getItems = async(ctx: BvTableCtxObject) => {
      const queryParams = {
        'filter[archived]': false,
        'page[number]': ctx.currentPage,
        'page[size]': ctx.perPage,
        'sort': '-createdAt',
        'include': 'care_receivers',
      }
      await root.$store.dispatch('households/load', queryParams)
      const idCarrier = extractRelationshipIds(root.$store.state.households.response, 'lead')
      const leadQueryParams = {
        'filter[id]':idCarrier.join(','),
        'page[size]': idCarrier.length,
        'include': 'address'
      }
      await root.$store.dispatch('leads/load', leadQueryParams)
      const response = root.$store.state.households.response
      response.included = response.included?.concat(root.$store.state.leads.data)
      apiDataMorpher(response, queryParams.include+',lead')
      return response.data
    }

    const columns = ref([
      {
        component: () => import("@/components/shared/table/cells/LinkCell.vue"),
        label: "Familienname",
        title: "Familienname",
        key: 'familyNames',
        sortable: true,
        options: {
          contentFunction: (data: any) => {
            const name = data.item.attributes.care_receivers
              .map(cr => cr.attributes.lastName).join(", ")
            return name !== '' ? name : 'Nicht vorhanden'
          },
          hrefFunction: (data: any) => `/${data.item.type}/${data.item.id}`
        }
      },
      {
        component: () => import("@/components/shared/table/cells/LinkCell.vue"),
        label: "Lead",
        title: "Lead",
        key: 'lead.fullName',
        sortable: true,
        options: {
          contentFunction: (data: any) => {
            return fullName(data.item.attributes.lead.attributes.address)
          },
          hrefFunction: (data: any) => {
            return `/leads/${data.item.attributes.lead.id}`
          }
        }
      },
      {
        component: () => import("@/components/shared/table/cells/ValueCell.vue"),
        label: "Patienten",
        title: "Patienten",
        key: 'careReceiverCount',
        sortable: true,
        options: {
          contentFunction: (data: any) => data.item.attributes.care_receivers.length
        }
      },
      {
        component: () => import("@/components/shared/table/cells/ValueCell.vue"),
        label: "Team Info",
        title: "Team Info",
        key: 'attributes.hotNotes',
        sortable: true,
      },
      {
        component: () => import("@/components/shared/table/cells/IconCell.vue"),
        label: "Paket versand",
        title: "Paket versand",
        key: 'attributes.packageSent',
        sortable: true,
        options: {
          mappedIcons: [
            {
              value: false,
              stack: [
                {
                  icon: 'times',
                  class: 'text-muted'
                }
              ]
            },
            {
              value: true,
              stack: [
                {
                  icon: 'check',
                  class: 'text-muted'
                }
              ]
            },
          ]
        }
      },
      {
        component: () => import("@/components/shared/table/cells/IconCell.vue"),
        label: "Trustpilot",
        title: "Trustpilot",
        key: 'attributes.trustpilot',
        sortable: true,
        options: {
          mappedIcons: [
            {
              value: false,
              stack: [
                {
                  icon: 'times',
                  class: 'text-muted'
                }
              ]
            },
            {
              value: true,
              stack: [
                {
                  icon: 'check',
                  class: 'text-muted'
                }
              ]
            },
          ]
        }
      },
      {
        component: () => import("@/components/shared/table/cells/IconCell.vue"),
        label: "Archiviert",
        title: "Archiviert",
        key: 'attributes.archived',
        sortable: true,
        options: {
          mappedIcons: [
            {
              value: false,
              stack: [
                {
                  icon: 'times',
                  class: 'text-muted'
                }
              ]
            },
            {
              value: true,
              stack: [
                {
                  icon: 'check',
                  class: 'text-muted'
                }
              ]
            },
          ]
        }
      },
      {
        component: () => import("@/components/shared/table/cells/IconCell.vue"),
        label: "Schläft",
        title: "Dormant",
        key: 'attributes.dormant',
        sortable: true,
        options: {
          mappedIcons: [
            {
              value: false,
              stack: [
                {
                  icon: 'times',
                  class: 'text-muted'
                }
              ]
            },
            {
              value: true,
              stack: [
                {
                  icon: 'check',
                  class: 'text-muted'
                }
              ]
            },
          ]
        }
      },
      {
        component: () => import("@/components/shared/table/cells/ValueCell.vue"),
        label: "Allein lebend",
        title: "Allein lebend",
        key: 'attributes.livingAlone',
        sortable: true,
        options: {
          mappedIcons: [
            {
              value: false,
              stack: [
                {
                  icon: 'times',
                  class: 'text-muted'
                }
              ]
            },
            {
              value: true,
              stack: [
                {
                  icon: 'check',
                  class: 'text-muted'
                }
              ]
            },
          ]
        }
      }
    ])
    return {
      getItems,
      totalItems,
      columns
    }
  }
})
